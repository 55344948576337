import {openDB, IDBPObjectStore, StoreNames, IDBPDatabase, DBSchema} from "idb";


interface StorageSchema extends DBSchema {
    timeKeeping: {
        key: number;
        value: Task,
        indexes: {
            startIndex: Date;
        }
    }
}

export interface Task {
    id?: number;
    start: Date;
    end: Date;
    notes: string;
}

export class TimeStorage {
    private db: IDBPDatabase<StorageSchema> | undefined;
    private ready: boolean = false;
    private objectStore: IDBPObjectStore<StorageSchema, ArrayLike<StoreNames<StorageSchema>>, "timeKeeping", "versionchange"> | undefined;

    public get isReady() {
        return this.ready;
    }

    async open() : Promise<void> {
        this.db = await openDB<StorageSchema>("timeKeepingDB", 1, {
            upgrade: (db) => {
                this.objectStore = db.createObjectStore("timeKeeping", {keyPath: 'id', autoIncrement: true});
                this.objectStore.createIndex("startIndex", "start", {unique: false});
                this.ready = true;
            }
        });
    }

    async storeTask(start: Date, end : Date, note: string | undefined) : Promise<number> {
        if (!this.db) {
            throw new Error("Database not initialized");
        }
        return await this.db.add("timeKeeping", {
            start: start,
            end: end,
            notes: note || "",
        });
    }

    async updateNotes(id: number, notes: string) : Promise<void> {
        if (!this.db) {
            throw new Error("Database not initialized");
        }
        let currentValue = await this.db.get("timeKeeping", id);
        if (!currentValue) {
            throw new Error("Record not found");
        }
        await this.db.put("timeKeeping", {...currentValue, notes});
    }

    async fetchDailyTasks(day: Date): Promise<{[key: number]: Task}> {
        if (!this.db) {
            throw new Error("Database not initialized");
        }
        let start = new Date(day.getFullYear(), day.getMonth(), day.getDate());
        let end = new Date(day.getFullYear(), day.getMonth(), day.getDate() + 1);
        let result = await this.db.getAllFromIndex("timeKeeping", "startIndex", IDBKeyRange.bound(start, end));
        let map: {[key: number]: Task} = {};
        for (let task of result) {
            map[task.id!] = task;
        }
        return map;
    }

    async fetchTask(id: number): Promise<Task> {
        if (!this.db) {
            throw new Error("Database not initialized");
        }
        let result = await this.db.get("timeKeeping", id);
        if (!result) {
            throw new Error("Record not found");
        }
        return result;
    }

    setCurrentTaskStart(when: Date | undefined) {
        if (when) {
            localStorage["startedTask"] = when.toISOString();
        } else {
            localStorage.removeItem("startedTask");
        }
    }

    getCurrentTaskStart(): Date | undefined {
        if (localStorage["startedTask"]) {
            return new Date(localStorage["startedTask"]);
        }
    }

    setCurrentTaskNote(note: string | undefined) {
        if (note) {
            localStorage["currentNote"] = note;
        } else {
            localStorage.removeItem("currentNote");
        }
    }

    getCurrentTaskNote(): string | undefined {
        return localStorage["currentNote"];
    }
}