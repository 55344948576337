import React from "react";

export interface TrackButtonProps {
    running: boolean;
    startTime?: Date;
    callback: () => void;
}

export function TrackButton(props: TrackButtonProps) {
    return (
        <div className="TrackButton-container">
            <button className="TrackButton-button"
                    onClick={() => props.callback()}>{props.running ? "Stop" : "Start"}</button>
        </div>
    )
}