import {Task} from "./storage";
import React, {useEffect, useState} from "react";

export interface TimelineProps {
    entries: { [key: number]: Task };
    runningStartTime?: Date;
    onSelect?: (id: number | 'new') => void;
    selectedEntry: number | 'new' | undefined;
}

export function Timeline(props: TimelineProps) {
    const [now, setNow] = useState(new Date());

    useEffect(() => {
        if (props.runningStartTime) {
            const interval = setInterval(() => setNow(new Date()), 1000);
            setNow(new Date());
            return () => clearInterval(interval);
        }
    }, [props.runningStartTime]);

    const START_TIME = 25200;
    const END_TIME = 68400;

    function timeToPercent(time: Date) {
        let secondsSinceMidnight = time.getHours() * 3600 + time.getMinutes() * 60 + time.getSeconds();
        return (secondsSinceMidnight - START_TIME) / (END_TIME - START_TIME) * 100;
    }

    let entries: { [key: string]: Task } = props.entries;
    if (props.runningStartTime) {
        entries = {...entries, "new": {start: props.runningStartTime, end: now, notes: "", id: -1}};
    }
    return (
        <div className="Timeline-container">
            <div className="Timeline-box">
                {Object.values(entries).map((entry) => (
                    <div
                        className={`Timeline-entry ${(entry.id === props.selectedEntry || (entry.id === -1 && props.selectedEntry === 'new')) ? 'selected' : ''}`}
                        key={entry.id}
                        style={{
                            left: `${timeToPercent(entry.start)}%`,
                            width: `${timeToPercent(entry.end) - timeToPercent(entry.start)}%`,
                            height: '100%'
                        }
                        }
                        onClick={() => {
                            if (props.onSelect) {
                                props.onSelect(entry.id! === -1 ? "new" : entry.id!);
                            }
                        }}
                    ></div>
                ))}
            </div>
            <div className="Timeline-timestamp-box">
                <div className="Timeline-timestamp" style={{left: 0}}>{START_TIME / 3600}:00 AM</div>
                {Object.values(entries).map((entry) => (
                    <div className="Timeline-timestamp" key={entry.id}
                         style={{left: `${timeToPercent(entry.start)}%`}}>{entry.start.toLocaleTimeString()}</div>
                ))}
                <div className="Timeline-timestamp" style={{left: '100%'}}>{END_TIME / 3600 - 12}:00 PM</div>
            </div>
        </div>
    )
}