import React, {useEffect, useState} from 'react';
import './App.css';
import {Task, TimeStorage} from "./storage";
import {TrackButton} from "./TrackButton";
import {Timeline} from "./Timeline";
import {EntryNotes} from "./EntryNotes";
import {TimeList} from "./TimeList";

function App() {
    const [storage, setStorage] = useState(new TimeStorage());
    const [currentStartTime, setCurrentStartTime] = useState<Date | undefined>(undefined);
    const [selectedEntry, setSelectedEntry] = useState<number | 'new' | undefined>(undefined);
    const [runningTaskNotes, setRunningTaskNotes] = useState<string | undefined>(undefined);
    const [entries, setEntries] = useState<{[key: number]: Task}>({});


    useEffect(() => {
        (async() => {
            await storage.open();
            setEntries(await storage.fetchDailyTasks(new Date()));
            setRunningTaskNotes(storage.getCurrentTaskNote());
            setCurrentStartTime(storage.getCurrentTaskStart());
            if (storage.getCurrentTaskStart()) {
                setSelectedEntry("new");
            }
        })();
    }, [storage]);

    async function toggleRunning() {
        if (!currentStartTime) {
            const startTime = new Date();
            setCurrentStartTime(startTime);
            setSelectedEntry("new");
            storage.setCurrentTaskStart(startTime);
            return;
        }
        storage.setCurrentTaskStart(undefined);
        const endTime = new Date();
        const note = runningTaskNotes || "";
        let storedTaskId = await storage.storeTask(currentStartTime!, endTime, note);
        let newEntries = {...entries, [storedTaskId]: {start: currentStartTime!, end: endTime, notes: note, id: storedTaskId}};
        setEntries(newEntries);
        setCurrentStartTime(undefined);
        storage.setCurrentTaskStart(undefined);
        setRunningTaskNotes(undefined);
        storage.setCurrentTaskNote(undefined);
        if (selectedEntry === "new") {
            setSelectedEntry(storedTaskId);
        }
    }

    const currentNotes = () => {
        console.log(`Getting notes for ${selectedEntry}...`);
        if (!selectedEntry) {
            return "";
        }
        if (selectedEntry === "new") {
            return runningTaskNotes || "";
        }
        return entries[selectedEntry].notes;
    }

    const updateCurrentNotes = async (newContent: string) => {
        if (!selectedEntry) {
            return;
        }
        if (selectedEntry === "new") {
            setRunningTaskNotes(newContent);
            storage.setCurrentTaskNote(newContent);
            return;
        }
        let newEntries = {...entries};
        newEntries[selectedEntry] = {...newEntries[selectedEntry], notes: newContent};
        setEntries(newEntries);
        await storage.updateNotes(selectedEntry, newContent);
    }

    const updateSelectedTask = (id: number | 'new') => {
        setSelectedEntry(id);
    }

    const selectedStartTime = () => {
        if (!selectedEntry) {
            throw new Error("This shouldn't be called without a start time");
        }
        if (selectedEntry === "new") {
            return currentStartTime!;
        }
        return entries[selectedEntry].start;
    }

    const selectedEndTime = () => {
        if (!selectedEntry) {
            return undefined;
        }
        if (selectedEntry === "new") {
            return undefined;
        }
        return entries[selectedEntry].end;
    }

    return (
        <div className="App">
            <h1>Time Tracking</h1>
            <TrackButton running={!!currentStartTime} callback={toggleRunning} />
            <Timeline entries={entries} runningStartTime={currentStartTime} selectedEntry={selectedEntry} onSelect={updateSelectedTask} />
            {
                selectedEntry === undefined ? <p>Select an entry to view notes.</p> : <EntryNotes startTime={selectedStartTime()} endTime={selectedEndTime()} content={currentNotes()} onChange={updateCurrentNotes} />
            }
            <hr />
            <TimeList entries={entries} onSelect={updateSelectedTask} selectedEntry={selectedEntry} runningStartTime={currentStartTime} />
        </div>
    );
}

export default App;
