import React, {useEffect, useState} from "react";

export interface EntryNotesProps {
    startTime: Date;
    endTime: Date | undefined;
    content: string;
    onChange: (newContent: string) => void;
}

export function EntryNotes(props: EntryNotesProps) {
    const [now, setNow] = useState(new Date());

    useEffect(() => {
        if (!props.endTime) {
            const interval = setInterval(() => setNow(new Date()), 1000);
            setNow(new Date());
            return () => clearInterval(interval);
        }
    }, [props.endTime]);

    function formatDuration(duration: number) {
        let seconds = Math.floor(duration / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        seconds = seconds % 60;
        minutes = minutes % 60;
        return `${hours}h ${minutes}m ${seconds}s`;
    }

    return (
        <div className="EntryNotes-container">
            <h2>Notes</h2>
            <p>{props.startTime.toLocaleTimeString()} to {
                props.endTime ? props.endTime.toLocaleTimeString() : `now (${formatDuration(now.getTime() - props.startTime.getTime())})`
            }</p>
            <textarea className="EntryNotes-textarea" value={props.content}
                      onChange={(e) => props.onChange(e.target.value)}/>
        </div>
    )
}