import {Task} from "./storage";
import React from "react";

export interface TimeListProps {
    entries: { [p: number]: Task },
    runningStartTime: Date | undefined,
    onSelect: (id: (number | "new")) => void
    selectedEntry: number | 'new' | undefined;
}

export function TimeList(props: TimeListProps) {
    let entries: { [key: string]: Task } = props.entries;
    if (props.runningStartTime) {
        entries = {...entries, "new": {start: props.runningStartTime, end: new Date(), notes: "", id: -1}};
    }
    return (
        <ul className="TimeList-list">
            {Object.values(entries).map((entry) => (
                <li key={entry.id}
                    className={(entry.id === props.selectedEntry || (entry.id === -1 && props.selectedEntry === 'new')) ? 'selected' : undefined}
                    onClick={() => props.onSelect(entry.id! === -1 ? "new" : entry.id!)}>{entry.start.toLocaleTimeString()} - {entry.id === -1 ? "now" : entry.end?.toLocaleTimeString()}</li>
            ))}
        </ul>
    )
}